<template>
  <div class="pop-header">
    <div class="pop-header__left"></div>
    <div class="pop-header__center">
      {{title}}
    </div>
    <div class="pop-header__right"></div>
  </div>
</template>

<script>
export default {
  name: 'PopHeader',
  props: [
    'title'
  ],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
