<template>
  <div class="auth-page">
    <pop-header :title="$t('auth.register')"/>
    <div class="auth-page__container">
      <img src="/images/logo-1.png" alt=""/>
      <div class="line-separator"/>
      <form @submit.prevent="onRegister">
        <div class="form-element">
          <label>{{$t('auth.name')}}</label>
          <input type="text" @input="onInput('name', $event.target.value)" class="form-control" required/>
        </div>
        <div class="form-element">
          <label>{{$t('auth.email')}}</label>
          <input type="email" @input="onInput('email', $event.target.value)" class="form-control" required/>
        </div>
        <div class="form-element mg-t-15">
          <label>{{$t('auth.password')}}</label>
          <input type="password" @input="onInput('password', $event.target.value)" class="form-control" required/>
        </div>
        <div class="form-element mg-t-15">
          <label>{{$t('auth.password_repeat')}}</label>
          <input type="password" @input="onInput('password2', $event.target.value)" class="form-control" required/>
        </div>
        <div class="form-element mg-t-15">
          <label>{{$t('auth.code')}}</label>
          <input type="text" @input="onInput('code', $event.target.value)" class="form-control" required/>
        </div>
        <div class="mg-t-40">
          <button type="submit" class="btn btn--green blocked">{{$t('auth.register')}}</button>
          <RouterLink to="/login" class="mg-t-10 btn btn--green-outline blocked">{{$t('auth.login')}}</RouterLink>
        </div>
      </form>
    </div>
    <loading v-if="isLoading"/>
  </div>
</template>

<script>
import Loading from '@/components/loading/Loading'
import PopHeader from '@/components/popHeader/popHeader'
import { Auth, User } from '@/api/api'
import { mapGetters } from 'vuex'
export default {
  name: 'Register',
  components: { Loading, PopHeader },
  data () {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        password2: '',
        code: ''
      },
      resetEmail: '',
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed'
    })
  },
  updated () {
    if (this.isAuthed) {
      this.$router.push('/places')
    }
  },
  mounted () {
  },
  methods: {
    redirect (url) {
      window.location = url
    },
    onInput (key, value) {
      this.form = {
        ...this.form,
        [key]: value
      }
    },
    async onRegister () {
      if (this.form.password !== this.form.password2) {
        this.$toast.error(this.$t('auth.pass_error'))
        return
      }

      this.isLoading = true

      try {
        const { data } = await Auth.register(
          this.form.name,
          this.form.email,
          this.form.password,
          this.form.code.split('_')[0] || '', 'd_' + Date.now()
        )

        Auth.setToken(data.token)

        const user = await User.me()

        this.$store.dispatch('auth/login', {
          token: data.token,
          user: {
            ...user.data
          }
        })
      } catch (e) {
        this.$toast.error(this.$t('auth.register_failed'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
